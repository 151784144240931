import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

import HomePage from "./pages/PersonalPortfolio";
import portfolioThreeColumn from "./elements/portfolio/PortfolioThreeColumn";
import PortfolioDetails from "./pages/PortfolioDetails";
import Contact from "./elements/contact/Contact";
import EventConference from "./pages/EventConference";
import Error from "./pages/Error";
import InstagramMarketing from "./pages/InstagramMarketing";
import AboutMe from "./pages/AboutMe";
import CafeDio from "./pages/CafeDio";
import "./assets/scss/style.scss";

const App = () => {
  return (
    <>
      <Router>
        <PageScrollTop>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              exact
              component={HomePage}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about-me"}`}
              exact
              component={AboutMe}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-three-column"}`}
              exact
              component={portfolioThreeColumn}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              exact
              component={Contact}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`}
              exact
              component={PortfolioDetails}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/event-marketing"}`}
              exact
              component={EventConference}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/instagram-marketing"}`}
              exact
              component={InstagramMarketing}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/cafe-dio"}`}
              exact
              component={CafeDio}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/error"}`}
              exact
              component={Error}
            />
          </Switch>
        </PageScrollTop>
      </Router>
    </>
  );
};

export default App;
