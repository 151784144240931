import React from "react";
const AboutSym = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/diopars/meSym.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  About Diopars Symposium <br />{" "}
                </h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  The second Diaopars Digital Implant Symposium was held on
                  January 16 and 17, 2020, with the presence of more than 400
                  dental specialists at Spinas Palace Hotel in Tehran. <br />
                  This marketing event was carefully planned to make this event
                  held with the highest quality both scientifically and in terms
                  of quality.
                  <br /> At that time, I was the Marketing Manager of Diopars
                  Company, who was responsible for the pre-event, holding the
                  event, and also post-event.
                  <br /> This project started about 45 days before symposium,
                  which includes the final selection of professors, preparation
                  of scientific content and integration according to the brand
                  identity, marketing materials, gifts for professors, selecting
                  brand ambassadors,booths decoration, Recording Interviews,
                  advertising teasers and developing Landing Page of symposium.{" "}
                  <br />I can boldly say that this event is one of the most
                  important examples of my portfolio, which includes marketing,
                  digital marketing, teasers, web design, holding events, etc.
                </p>
                <div className="download-button mt--20">
                  <a
                    className="btn-read-more"
                    href="https://diopars.com/symposium2020/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Symposium Landing Page</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSym;
