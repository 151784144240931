import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "./Skill";

const progressData = [
  {
    id: 1,
    title: "Python",
    percantage: 75,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 2,
    title: "Django",
    percantage: 80,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 3,
    title: "React",
    percantage: 80,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 4,
    title: "React Native",
    percantage: 70,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 5,
    title: "Next.js",
    percantage: 65,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 6,
    title: "SQL",
    percantage: 55,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 7,
    title: "Bootstrap",
    percantage: 90,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 8,
    title: "Docker",
    percantage: 70,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 9,
    title: "Linux",
    percantage: 65,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
];

const DevelopingSkills = () => {
  return (
    <>
      {progressData.map((progress) => (
        <TrackVisibility once key={progress.id} className="single-progress">
          <Skill progress={progress} />
        </TrackVisibility>
      ))}
    </>
  );
};

export default DevelopingSkills;
