import React, { useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
const PortfolioItem = ({ portfolio }) => {
  return (
    <>
      <div className="rwt-card">
        <div className="inner">
          <div className="thumbnail">
            <figure className="card-image">
              <a
                target="_blank"
                href={
                  portfolio.page_link
                    ? process.env.PUBLIC_URL + portfolio.page_link
                    : process.env.PUBLIC_URL +
                      `/portfolio-details/${portfolio.id}`
                }
              >
                <img
                  className="img-fluid"
                  src={"https://manager.rismanchi.com" + portfolio.image}
                  alt="Portfolio-01"
                />
              </a>
            </figure>

            <a
              target="_blank"
              href={
                portfolio.page_link
                  ? process.env.PUBLIC_URL + portfolio.page_link
                  : process.env.PUBLIC_URL +
                    `/portfolio-details/${portfolio.id}`
              }
              className="rwt-overlay"
            ></a>
          </div>
          <div className="content">
            <h5 className="title mb--10">
              <a
                target="_blank"
                href={
                  portfolio.page_link
                    ? process.env.PUBLIC_URL + portfolio.page_link
                    : process.env.PUBLIC_URL +
                      `/portfolio-details/${portfolio.id}`
                }
              >
                {portfolio.title}
              </a>
            </h5>

            <span className="subtitle b2 text-capitalize">
              {portfolio.category.name}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default PortfolioItem;
