import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "./Skill";

const progressData = [
  {
    id: 1,
    title: "Marketing Plan & Business Model",
    percantage: 70,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 2,
    title: "Advertising",
    percantage: 85,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 3,
    title: "Branding",
    percantage: 80,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 4,
    title: "CRM",
    percantage: 65,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 5,
    title: "Digital Marketing",
    percantage: 85,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 6,
    title: "Graphic Design",
    percantage: 70,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
  {
    id: 7,
    title: "Video Editing",
    percantage: 50,
    progressColor:
      "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
  },
];

const MarketingSkills = () => {
  return (
    <>
      {progressData.map((progress) => (
        <TrackVisibility once key={progress.id} className="single-progress">
          <Skill progress={progress} />
        </TrackVisibility>
      ))}
    </>
  );
};

export default MarketingSkills;
