import React, { useEffect, useState } from "react";
import LoadingSpiner from "./loadingPage";
const CheckLoading = () => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    window.addEventListener("load", (event) => {
      setShow(false);
      console.log("page Loaded");
    });
  }, []);
  return <LoadingSpiner show={show} />;
};

export default CheckLoading;
