import { createSlice } from "@reduxjs/toolkit";

const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
  },
  reducers: {
    addBlog(state, action) {
      const newBlog = action.payload;
      const existingBlog = state.blogs.find((blog) => blog.id === newBlog.id);
      if (!existingBlog) {
        state.blogs.push({
          id: newBlog.id,
          title: newBlog.title,
          description: newBlog.description,
        });
      } else {
      }
    },
  },
});

export const blogsActions = blogsSlice.actions;
export default blogsSlice;
