import React from "react";
const LoadingSpiner = ({ show }) => {
  return (
    <>
      {show ? (
        <div
          style={{
            position: "fixed",
            display: "flex",
            flex: "1",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(0, 0, 0, 1)",
            zIndex: 3000,
          }}
        >
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              <div style={{ width: "100%" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/logo/SignWhite.png`}
                  width="110"
                  height="110"
                  alt="Mehdi Rismanchi"
                />
              </div>
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LoadingSpiner;
