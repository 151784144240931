import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
  {
    form: "Industrial Managment Institute",
    description:
      "This is to certify that Mehdi Rismanchi has successfully completed the requirements for the 165-hour course of Marketing Managment offered on 28/12/2016",
    name: "Mohammad Ali Mohammadi",
    subtitle: "Managing Director",
    image: "testimonial-dark-01",
  },
  {
    form: "Arman Higher Education Institute",
    description:
      "This is to certify MEHDI RISMANCHI has successfully completed the requirements for 200-hou course of Digital Marketing Managment, offerd from 29/02/2020 to 31/08/2020, scoring 19 out of 20.",
    name: "Hamidreza Mehrvar",
    subtitle: "President of the Institute",
    image: "testimonial-dark-02",
  },
  {
    form: "MPT Academy",
    description:
      "This is to certify that Mehdi Rismanchi Has attended a training course under the subject of Google Adwords offered on 11 August 2019",
    name: "MPT Academy International Certification",
    subtitle: "Conference & Training Department",
    image: "testimonial-dark-03",
  },
  {
    form: "Diopars Co.",
    description:
      "Mr. Mehdi Rismanchi Throught your two years of service as our Marketing Manager you have been not only a co-worker and mentor, but a friend to many\
      You maybe leaving us today but your legacy will remain forever.\
      ",
    name: "Mohamad Minaei",
    subtitle: "Managing Director",
    image: "testimonial-dark-04",
  },
  {
    form: "Pourateb Medical & Pharmaceutical Co.",
    description:
      "This is to certify that MEHDI RISMANCHI has worked full-time in this company as an Advertising Manager From 20/04/2008 to 21/01/2012",
    name: "Dr. Hamidreza Beheshti",
    subtitle: "Managing Director",
    image: "testimonial-dark-05",
  },
  {
    form: "Abadgaran Co.",
    description:
      "Dear MEHDI RISMANCHI, Depending on your expertise and work experience and with aim of making the most of your expertise, you are herewith apponted as the Manager of Electronic Commerce of Abadgaran Construction Chemical Industries Company.",
    name: "Alborz Majzoob",
    subtitle: "Managing Director & Chirman of the Board of Directors",
    image: "testimonial-dark-06",
  },
];
const TestimonialThree = ({ teamStyle }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider
          className="slick-space-gutter--5 rn-slick-dot rn-slick-arrow mb--40"
          {...slickDot}
        >
          {TestimonialData.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                  <div className="content mt_sm--40">
                    <span
                      style={{ fontSize: 21, fontWeight: "bold" }}
                      className="form"
                    >
                      {data.form}
                    </span>
                    <p style={{ fontSize: 24 }} className="description">
                      {data.description}
                    </p>
                    <div className="client-info">
                      <h4 className="title">{data.name}</h4>
                      <h6 className="subtitle">{data.subtitle}</h6>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={`./images/testimonial/${data.image}.jpg`}
                      alt="Industrial Managment Institute"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
