import React from "react";
import SEO from "../../common/SEO";
const PortfolioDetailsContent = ({ data }) => {
  return (
    <>
      <div className="rwt-portfolio-details rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="inner">
                <div className="details-list">
                  <div className="thumbnail alignwide">
                    <img
                      className="radius w-100"
                      src={`https://manager.rismanchi.com${data.image_large}`}
                      alt="Corporate Image"
                    />
                  </div>

                  <div className="row mt--40 row--30">
                    <div className="col-lg-6">
                      <div className="content-left">
                        <h4 className="title">{data.title}</h4>
                        <div className="single-list-wrapper">
                          <div className="single-list">
                            <label>Date:</label>
                            <span>{data.date}</span>
                          </div>
                          <div className="single-list">
                            <label>Client:</label>
                            <span>{data.client}</span>
                          </div>
                          <div className="single-list">
                            <label>Category:</label>
                            <span>{data.category.name}</span>
                          </div>
                        </div>
                        {data.btn_link ? (
                          <div className="view-btn mt--50">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="btn-default btn-large round"
                              href={`${data.btn_link}`}
                            >
                              {data.btn_buttontext}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                      <div className="content-right">
                        <h5 className="subtitle">{data.subtitle}</h5>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.body }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portfolio-gallery mt--40">
                  {data.gallery.map((gal) => {
                    return (
                      <div className="gallery mt--30" key={gal.id}>
                        <div className="thumbnail">
                          <img
                            className="radius w-100"
                            src={`https://manager.rismanchi.com${gal.image}`}
                            alt="Mehdi Rismanchi"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioDetailsContent;
