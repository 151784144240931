import React from "react";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";

const CopyrightTwo = () => {
  return (
    <div className="copyright-area copyright-style-one variation-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-7 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
                <li>
                  <a href="/">Terms And Condition</a>
                </li>
                <li>
                  <a href="/contact">Contact Me</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-5 mt_sm--20">
            <div className="copyright-center text-center">
              <ul className="social-icon social-default color-lessdark justify-content-center">
                <li>
                  <a
                    href="https://www.facebook.com/mehdi.rismanchi.9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiFacebook />
                  </a>
                </li>
                {/* <li>
                  <Link to="twitter.com">
                    <FiTwitter />
                  </Link>
                </li> */}
                <li>
                  <a
                    href="https://www.instagram.com/m.rismanchi/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/mehdi-rismanchi-05689b53/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiLinkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
            <div className="copyright-right text-center text-lg-right">
              <p className="copyright-text">
                Copyright All rights reserved © {new Date().getFullYear()} Mehdi
                Rismanchi.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyrightTwo;
