import { portfoliosActions } from "./portfolio-slice";

export const fetchAll = () => {
  return async (dispatch) => {
    const fetchPortfolio = async () => {
      const response = await fetch(
        "https://manager.rismanchi.com/api/portfolio/all"
      );
      if (!response.ok) {
        throw new Error("Could not fetch portfolio data!");
      }
      const data = await response.json();
      return data;
    };

    const fetchCategories = async () => {
      const response = await fetch(
        "https://manager.rismanchi.com/api/portfolio/categories"
      );
      if (!response.ok) {
        throw new Error("Could not fetch categories data!");
      }
      const data = await response.json();
      return data;
    };

    try {
      const portfolioData = await fetchPortfolio();
      const categoriesData = await fetchCategories();
      dispatch(portfoliosActions.loadCategories(categoriesData));
      dispatch(portfoliosActions.loadPortfolio(portfolioData));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchPortfolioById = (id) => {
  return async (dispatch) => {
    const fetchPortfolio = async () => {
      const response = await fetch(
        "https://manager.rismanchi.com/api/portfolio/id/" + id
      );
      if (!response.ok) {
        throw new Error("Could not fetch portfolio data!");
      }
      const data = await response.json();
      return data;
    };
    try {
      const portfolioSelected = await fetchPortfolio();
      dispatch(portfoliosActions.selectedPortfolio(portfolioSelected));
    } catch (error) {
      console.log(error);
    }
  };
};
