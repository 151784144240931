import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterThree from "../common/footer/FooterThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import DevelopingSkills from "../elements/progressbar/DevelopingSkills";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import MyTopo from "../components/myTopo";
import AboutCircle from "../components/aboutCircle";
import VfxRGB from "../components/VfxRGB";
import MarketingSkills from "../elements/progressbar/MarketingSkills";
import ComputerSoftwares from "../elements/progressbar/ComputerSoftwares";
import { isMobile } from "react-device-detect";
import TimeLineCare from "../elements/timeline/TimelineCareer";
import SectionTitleNoAnimation from "../elements/sectionTitle/SectionTitleNoAnimation";

const AboutMe = () => {
  return (
    <>
      <SEO title="About" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        {/* <MyTopo> */}
        {isMobile ? (
          <div className="slider-area slider-style-1 bg-transparent">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <div
                      className="react-image mt--100"
                      style={{ height: 127 }}
                    >
                      <VfxRGB
                        width={127}
                        height={127}
                        url="./images/logo/SignWhite.png"
                        alt="Mehdi Rismanchi"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <AboutCircle>
            <div className="slider-area slider-style-1 bg-transparent height-750">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner text-center">
                      <div
                        className="react-image mb--30"
                        style={{ height: 127 }}
                      >
                        <VfxRGB
                          width={127}
                          height={127}
                          url="./images/logo/SignWhite.png"
                          alt="Mehdi Rismanchi"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AboutCircle>
        )}

        {/* End Slider Area  */}
        {/* <Separator /> */}
        <div id="aboutme">
          <div className="rwt-portfolio-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="title theme-gradient display-one text-center mb-3 mt-3">
                    My Career Journey
                  </h1>
                  <SectionTitleNoAnimation
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="1996-2026"
                    description="I was born in 1980 and when I was 12 years old, I got to know the world of programming for the first time. At that time I had a Commodore 64 and like all kids of that age loved computer games. After playing for a while, I became interested in making games, but I had no idea how to do it, until I was able to buy some books about this from a bookstore.
                    In short, I got to know the BASIC language and tried to make a game, but it was really harder than I thought, but in the end I was able to make some very simple games. In the process of making the game, I learned about animation and I just realized that it is much more difficult.
                    My familiarity with programming, graphics and animation as a child almost created my professional personality, and when I turned 16, I was able to earn money for the first time by selling my designs for packaging."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="aboutme">
          <div className="rwt-portfolio-area rn-section-gapBottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <TimeLineCare />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="aboutme">
          <div className="rwt-portfolio-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="title theme-gradient display-one text-center mb-3 mt-3">
                    My Working Skills
                  </h1>
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="1996-2022"
                    description=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Elements Area  */}
        <div className="rwt-about-area rn-section-gap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <SectionTitle
                  textAlign="text-start"
                  radiusRounded=""
                  subtitle="Never settle for less than great"
                  title="Developing Skills"
                  description="I have learned developing mostly by taking Udemy courses and reading blogs and watching YouTube. 
                    I believe that nowadays it is very easy to learn new topics, but what is important is what to learn and how to use it.
                    "
                />
              </div>
              <div className="col-lg-6">
                <div className="rbt-progress-style-1">
                  <DevelopingSkills />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-about-area rn-section-gap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <SectionTitle
                  textAlign="text-start"
                  radiusRounded=""
                  subtitle="Carrying creativity into action"
                  title="Marketing & Art Skills"
                  description="In my mind, marketing cannot be described in any way, because it is a really big universe but when you start acting you would find it familiar.<br>
                    The Idea , Research and creating  with required changes could be marketing.<br>
                    I studied marketing in industrial management organization and I was able to get MBA MARKETING certificate.<br>
                      I learned digital marketing at Arman Digital School and was able to get a certificate from this institution with an A grade.<br>
                    I learned graphic design in Master Golestani's classes and also got acquainted with the basics of art in this course.<br>"
                />
              </div>
              <div className="col-lg-6">
                <div className="rbt-progress-style-1">
                  <MarketingSkills />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-about-area rn-section-gap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <SectionTitle
                  textAlign="text-start"
                  radiusRounded=""
                  subtitle="Softwares Power Your Dreams."
                  title="Computer Softwares"
                  description="I have been using the computer as my work tool for many years and I have worked with many softwares in these years, but I am still interested in trying new softwares if there is an opportunity."
                />
              </div>
              <div className="col-lg-6">
                <div className="rbt-progress-style-1">
                  <ComputerSoftwares />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="Certificates & Appreciations"
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* </MyTopo> */}
        <FooterThree />
      </main>
    </>
  );
};
export default AboutMe;
