import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";
function VideoPortfolio({ galleryItem }) {
  const image = "https://manager.rismanchi.com" + galleryItem.image;
  const popupLink = [
    "https://manager.rismanchi.com" + galleryItem.video[0].video,
  ];
  const iconHandel = () => {
    return <FiPlay />;
  };
  const [toggler, setToggler] = useState(false);
  return (
    <>
      <div className="rn-gallery icon-center">
        <div className="thumbnail">
          <img
            className="radius-small"
            src={`${image}`}
            alt="Mehdi Rismanchi Portfolio"
          />
        </div>
        <div className="video-icon">
          <button
            className="btn-default rounded-player sm-size"
            onClick={() => setToggler(!toggler)}
          >
            <span>{iconHandel()}</span>
          </button>
        </div>
        <FsLightbox
          toggler={toggler}
          sources={popupLink}
          type="video"
          loadOnlyCurrentSource
          customAttributes={[
            {
              autoPlay: true,
            },
          ]}
        />
      </div>
      <div className="content mt--20 ml--8">
        <h5 className="title mb--10">{galleryItem.title}</h5>

        <span className="subtitle b2 text-capitalize">
          {galleryItem.category.name}
        </span>
      </div>
    </>
  );
}
export default VideoPortfolio;
