import React from "react";
import HtmlReactParser from 'html-react-parser';
import { isMobile } from "react-device-detect";
const TimelineData = [
  {
    id: "1",
    date: "2008",
    title: "My first official job as an employee at Pourateb",
    description:
      "my first official job started at Pourateb. At first, I was hired as \
      a graphic designer, but beacuse of my experience in providing\
      services and marketing, I was quickly promoted and became an\
      advertising manager. As an advertising manager, I made many changes\
      in the processes of the advertising department, and these\
      innovations led to the remarkable success of the advertising\
      department. In this regard, the CEO of Puratab gave me another\
      position as the web development manager. Since I had a lot of\
      knowledge in this field, I managed to design one of the best\
      pharmaceutical websites in the country, and as a result, our website\
      became a popular and successful website. I was working at Poratab\
      for about 4 years until I came up with a new idea about designing a\
      web application for estimating the budget of marketing campaigns.\
      Finally, in order to design that web application, I resigned from my\
      position and started developing my idea.\
      <br><br>\
      <h5>Key Qualifications & Responsibilities</h5>\
      <ul>\
      <li>Full stack web developer responsible for end-to-end web app development</li>\
      <li>Provided effective troubleshooting and remediation for web applications.</li>\
      <li>Effectively translated client requirements into application designs and systems requirements.</li>\
      <li>Followed policies and procedures related to application methods and quality standards at all times</li>\
      </ul>\
      <h5>Key Achievements</h5>\
      <ul>\
      <li>Designing and developing 7 websites of the organization with all necessary standards</li>\
      <li>Development and launch of online recruitment system</li>\
      <li>Development and launch of online medical consultation system.</li>\
      <li>Designing and developing a special contest for website visitors with the participation of more than 10,000 people per week</li>\
      <li>Design and development of web-based software for producing prescriptions for doctors in line with introducing products</li>\
      </ul>",
    image: "/images/brand/brand-01.png",
    margin: "0px 0px 0px -25px",
  },
  {
    id: "2",
    date: "2012",
    title: "Establishment of Hoorad Company",
    description:
      "Hoorad Company was founded by me in Tehran in 2012 in\
      order to provide web application development ،digital\
      marketing and advertising services. The main activity of\
      this company in the beginning was the development of the\
      Hoorad System web application, which lasted for about a\
      year. I developed Hoorad System's web application with C#,\
      jQuery and Ajax, and after finishing the programming, I\
      started marketing the product with the small team that I\
      had. The idea of the application was very unique and the\
      companies that were in the market had not seen something\
      like that and this made the sales process somehow\
      difficult. I tried hard to sell this web application, but\
      unfortunately, for various reasons, the sales were not up\
      sell the Hoorad system, I got to know many companies that\
      I was able to collaborate with them later.",
    image: "/images/brand/brand-21.png",
    margin: "0px 0px 0px 20px",
  },
  //   {
  //     id: "3",
  //     date: "2014",
  //     title: "Web Developer & Advertising Manager",
  //     description:
  //       "Design, Develop or maintain web sites, using authoring or scripting languages, content creation tools,\
  // management tools, and digital media. Identify problems uncovered by testing or customer feedback,\
  // and correct problems or refer problems to appropriate personnel for correction.Plan and\
  // prepare advertising and promotional material to increase sales of products or services,\
  // Gather and organize information to plan advertising campaigns.",
  //     image: "/images/brand/brand-09.png",
  //     margin: "0px 0px 0px -15px",
  //   },
  {
    id: "3",
    date: "2015",
    title: "Full Stack Developmer",
    description:
      "Boteh Sabz Company is one of the largest cosmetics and health companies in Iran, which is the\
      sales representative of Yves Rocher, Guinot, Hegor, Mavala, Dermeden and Eucerin products.\
      <br><br>\
      <h5>Key Qualifications & Responsibilities</h5>\
      <ul>\
      <li>Played a key role in the development, improvement, and operation of web-based software</li>\
      <li>Develop about 5 full-stack web applications to provide different services for clients</li>\
      <li>Optimize company’s complaint handling process by creating and delivering highly efficient app-based solution</li>\
        <li>Planned, wrote, and debugged web applications and software with complete accuracy.</li>\
      </ul>\
      <h5>Key Achievements</h5>\
      <ul>\
      <li>Increasing customer satisfaction and also increasing product sales because of launching the online consulting web application</li>\
      <li>Providing a suitable platform for ordering from all over the country due to the launch of the online store</li>\
      <li>Reducing the costs of paper advertising due to the creation of access to product brochures through the company's website.</li>\
      </ul>",
    image: "/images/brand/brand-03.png",
    margin: "0px 0px 0px -5px",
  },
  {
    id: "4",
    date: "2016",
    title: "Full Stack Developer",
    description:
      "ABDADGARAN is Construction Chemicals Company Which Founded in 1983 and initiated its business by establishing a workshop in Sheikh Abad Industrial Zone -Iran. I worked there as a Full Stack Developer & E-Commerce Manager.\
    <br><br>\
    <h5>Key Qualifications & Responsibilities</h5>\
    <ul>\
    <li>Developed, Improved, and operated web-based software</li>\
    <li>Applied new data processing systems for SQL databases that improved efficiency by over 20%</li>\
    <li>Developed apps to address problems within the company client's needs</li>\
    <li>Maximized applications’ efficiency, data quality, scope, operability, and flexibility.</li>\
    <li>Develop and implement a roadmap for the online store.</li>\
    </ul>\
    <br>\
    <h5>Key Achievements</h5>\
    <ul>\
    <li> Increasing the speed of providing services because of using the web application for the automatic design of product labels</li>\
    <li> Increasing product sales due to providing easy access to order information and factory production capacity</li>\
    </ul>",
    image: "/images/brand/brand-22.png",
    margin: "0px 0px 0px 22px",
  },
  // {
  //   id: "5",
  //   date: "2017",
  //   title: "Full Stack Developer & Marketing Manager",
  //   description:
  //     "Development and implementation web applications & marketing Strategies , media management ,\
  //     Influencer engagement , Budget setting and expense tracking , Branding , video marketing , Digital\
  //     marketing, Design, build, or maintain web sites, Perform or direct web site updates.Write, design, or\
  //     edit web page content, or direct others producing content.Identify problems uncovered by testing or\
  //     customer feedback, and correct problems or refer problems to appropriate personnel for correction.",
  //   image: "/images/brand/brand-05.png",
  //   margin: "0px 0px 0px 30px",
  // },

  {
    id: "5",
    date: "2019",
    title: "Full Stack Developer",
    description:
      "Diopars company is the largest dental equipment company, especially Fixture, in Iran, and I played a key role in this company as a full-stack developer.\
    <br><br>\
    <h5>Key Qualifications & Responsibilities</h5>\
    <ul>\
    <li>Developed full-stack web applications which processed, analyzed, and rendered data visually.</li>\
    <li>Liaised with art department & quality assurance testers, and CTO as needed.</li>\
    <li>Managed time-sensitive updates, including content changes and database upgrades.</li>\
    <li>Planned, wrote, and debugged web applications and software with complete accuracy.</li>\
    <li>media management , Influencer engagement , video marketing , Digital marketing</li>\
    </ul>\
    <br>\
    <h5>Key Achievements</h5>\
    <ul>\
    <li> Designing & Developing 5 websites for Daiopars brands</li>\
    <li> Development of online store and after sales services</li>\
    <li> Optimizing the codes of the organization's services in order to increase the response speed</li>\
    <li> Dockerizing and implementation of all services in accordance with the necessary standards and removing application security problems</li>\
    </ul>",
    image: "/images/brand/brand-06.png",
    margin: "0px 0px 0px -30px",
  },
];

const TimeLineCare = () => {
  return (
    <div className="rn-timeline-wrapper timeline-style-one position-relative">
      <div className="timeline-line"></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className="single-timeline mt--50" key={index}>
          <div className="timeline-dot">
            <div className="time-line-circle"></div>
          </div>
          <div className="single-content">
            {isMobile ? (
              <div className="text-center">
                <img
                  style={{ margin: data.margin }}
                  className="mt-5 mx_md-0 mx_sm-0"
                  src={process.env.PUBLIC_URL + data.image}
                />
              </div>
            ) : (
              <div>
                <img
                  style={{ margin: data.margin }}
                  className="mt-5 mx_md-0 mx_sm-0"
                  src={process.env.PUBLIC_URL + data.image}
                />
              </div>
            )}
            <div className="inner pt--15">
              <div className="row row--30 align-items-center">
                <div className="col-lg-12 mt_md--25 mt_sm--15 ">
                  <div className="content">
                    {isMobile ? (
                      <h3 className="title text-center">{data.title}</h3>
                    ) : (
                      <h3 className="title">{data.title}</h3>
                    )}

                    <p className="description">
                      {HtmlReactParser(data.description)}
                    </p>
                    <span>{data.date}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimeLineCare;
