import React, { useState, useEffect } from "react";
import PortfolioItem from "./PortfolioItem";
import { useSelector, useDispatch } from "react-redux";
import { fetchAll } from "../../store/portfolio-actions";
import VideoPortfolio from "../../components/VideoPortfolio";
const PortfolioOne = ({ Column }) => {
  const dispatch = useDispatch();
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(6);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const filters = useSelector((state) => state.portfolios.categories);
  const portfolios = useSelector((state) => state.portfolios.entities);
  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch]);

  useEffect(() => {
    if (filters.length !== 0) {
      setActiveFilter(filters[0].name.toLowerCase());
      setVisibleItems(portfolios.slice(0, dataVisibleCount));
    }
  }, [filters, portfolios]);
  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].name.toLowerCase()) {
      tempData = portfolios.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = portfolios.filter(
        (data) => data.category.name === e.target.textContent.toLowerCase()
      );
    }
    setVisibleItems(tempData.slice(0, dataVisibleCount));
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    console.log("visible:", dataVisibleCount, "Data:", portfolios.length);
    if (dataVisibleCount >= portfolios.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].name.toLowerCase()) {
        console.log("load more If");
        setVisibleItems(portfolios.slice(0, tempCount));
      } else {
        console.log("load more Else");
        const showData = portfolios.filter(
          (data) => data.category.name === activeFilter
        );
        setVisibleItems(showData.slice(0, tempCount));
        if (dataVisibleCount >= showData.length) {
          setNoMorePost(true);
        }
      }
    }
  };

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
            {filters.map((filter) => (
              <li className="list-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.name.toLowerCase() === activeFilter ? "current" : " "
                  }
                >
                  {filter.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="row row--15">
        {visibleItems.map((item) => (
          <div key={item.id} className={Column}>
            {item.category.name == "video" ? (
              <VideoPortfolio galleryItem={item} />
            ) : (
              <PortfolioItem portfolio={item} />
            )}
          </div>
        ))}
      </div>

      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rwt-load-more text-center mt--50">
            <button
              className="btn btn-default btn-large btn-icon"
              onClick={handleLoadmore}
              disabled={noMorePost ? "disabled" : null}
            >
              {noMorePost ? (
                "No Item Here"
              ) : (
                <span>
                  Load More
                  {/* <span className="icon">
                    <FaSpinner />
                  </span> */}
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioOne;
