import React, { useEffect, useState } from "react";
import PortfolioDetailsContent from "../elements/portfolio/PortfolioDetailsContent";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { fetchPortfolioById } from "../store/portfolio-actions";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpiner from "../components/loadingPage";

const PortfolioDetails = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const PortfolioData = useSelector((state) => state.portfolios.selected);
  const portfolioId = parseInt(id, 10);
  useEffect(() => {
    dispatch(fetchPortfolioById(portfolioId));
    setShow(true);
  }, []);

  if (!PortfolioData) {
    return <LoadingSpiner show={show} />;
  } else {
    return (
      <>
        <SEO title={PortfolioData.title + " || Portfolio"} />
        <Layout>
          <PortfolioDetailsContent data={PortfolioData} />
        </Layout>
      </>
    );
  }
};

export default PortfolioDetails;
