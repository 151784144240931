import React, { useState } from "react";
import { VFXImg } from "react-vfx";
import { VFXProvider } from "react-vfx";
const VfxRGB = ({ width, height, url, alt }) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <>
      <VFXProvider>
        <span
          style={{ width: { width }, height: { height } }}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <VFXImg src={url} shader="rgbShift" overflow="visibale" />
          ) : (
            <img width={width} height={height} src={url} alt={alt} />
          )}
        </span>
      </VFXProvider>
    </>
  );
};

export default VfxRGB;
