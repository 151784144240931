import { configureStore } from "@reduxjs/toolkit";

import blogsSlice from "./blog-slice";
import portfoliosSlice from "./portfolio-slice";
import logger from "redux-logger";
const store = configureStore({
  reducer: { blogs: blogsSlice.reducer, portfolios: portfoliosSlice.reducer },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
