import React from "react";
const AboutCafeDio = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/cafedio/AboutCafeDio.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  About DIO Cafe <br />{" "}
                </h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  DIO Cafe was an internal talk show that was conducted
                  selectively with video interviewing personnel. At first,
                  questions were asked about each person's role in the company,
                  and each person answered according to the assigned role in the
                  company. <br />
                  Also, in DIO Cafe, the host of the program asked somewhat
                  private questions to the employees and tried to add humor to
                  it.
                  <br /> In editing this video, new ideas were also used so that
                  we can increase the popularity of the program among the
                  employees of the company Various parts of this talk show were
                  recorded, and below I have included 2 of them for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCafeDio;
