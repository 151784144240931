import React from "react";
import VideoTwo from "../elements/video/VideoTwo";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import GalleryOne from "../elements/gallery/GalleryOne";
import AboutZibadent from "../elements/about/AboutZibadent";

const PopupData = [
  {
    id: "01",
    image: "./images/zibadent/smile.jpg",
    popupLink: ["./videos/zibadent1.mp4"],
  },
];

const PopupData2 = [
  {
    id: "01",
    image: "./images/zibadent/posts/1-1.jpg",
    popupLink: [
      "./images/zibadent/posts/1-1.jpg",
      "./images/zibadent/posts/1-2.jpg",
    ],
  },
  {
    id: "02",
    image: "./images/zibadent/posts/2.jpg",
    popupLink: ["./images/zibadent/posts/2.jpg"],
  },

  {
    id: "03",
    image: "./images/zibadent/posts/3.jpg",
    popupLink: ["./videos/zibadent2.mp4"],
  },
  {
    id: "04",
    image: "./images/zibadent/posts/4-1.jpg",
    popupLink: [
      "./images/zibadent/posts/4-1.jpg",
      "./images/zibadent/posts/4-2.jpg",
    ],
  },
  {
    id: "05",
    image: "./images/zibadent/posts/5.jpg",
    popupLink: ["./images/zibadent/posts/5.jpg"],
  },
  {
    id: "06",
    image: "./images/zibadent/posts/6.jpg",
    popupLink: ["./videos/zibadent1.mp4"],
  },
  {
    id: "07",
    image: "./images/zibadent/posts/7.jpg",
    popupLink: ["./images/zibadent/posts/7.jpg"],
  },

  {
    id: "08",
    image: "./images/zibadent/posts/8-1.jpg",
    popupLink: [
      "./images/zibadent/posts/8-1.jpg",
      "./images/zibadent/posts/8-2.jpg",
    ],
  },

  {
    id: "09",
    image: "./images/zibadent/posts/9.jpg",
    popupLink: ["./images/zibadent/posts/9.jpg"],
  },

  {
    id: "10",
    image: "./images/zibadent/posts/10.jpg",
    popupLink: ["./images/zibadent/posts/10.jpg"],
  },

  {
    id: "11",
    image: "./images/zibadent/posts/11.jpg",
    popupLink: ["./images/zibadent/posts/11.jpg"],
  },

  {
    id: "12",
    image: "./images/zibadent/posts/12-1.jpg",
    popupLink: [
      "./images/zibadent/posts/12-1.jpg",
      "./images/zibadent/posts/12-2.jpg",
    ],
  },

  {
    id: "13",
    image: "./images/zibadent/posts/13-1.jpg",
    popupLink: [
      "./images/zibadent/posts/13-1.jpg",
      "./images/zibadent/posts/13-2.jpg",
    ],
  },

  {
    id: "14",
    image: "./images/zibadent/posts/14.jpg",
    popupLink: ["./images/zibadent/posts/14.jpg"],
  },

  {
    id: "15",
    image: "./images/zibadent/posts/15.jpg",
    popupLink: ["./images/zibadent/posts/15.jpg"],
  },

  {
    id: "16",
    image: "./images/zibadent/posts/16.jpg",
    popupLink: ["./images/zibadent/posts/16.jpg"],
  },

  {
    id: "17",
    image: "./images/zibadent/posts/17.jpg",
    popupLink: ["./images/zibadent/posts/17.jpg"],
  },

  {
    id: "18",
    image: "./images/zibadent/posts/18-1.jpg",
    popupLink: [
      "./images/zibadent/posts/18-1.jpg",
      "./images/zibadent/posts/18-2.jpg",
    ],
  },
];

const PopupData3 = [
  {
    id: "01",
    image: "./images/zibadent/story/1.jpg",
    popupLink: ["./images/zibadent/story/1.jpg"],
  },

  {
    id: "02",
    image: "./images/zibadent/story/2.jpg",
    popupLink: ["./images/zibadent/story/2.jpg"],
  },

  {
    id: "03",
    image: "./images/zibadent/story/3.jpg",
    popupLink: ["./images/zibadent/story/3.jpg"],
  },

  {
    id: "04",
    image: "./images/zibadent/story/4.jpg",
    popupLink: ["./images/zibadent/story/4.jpg"],
  },

  {
    id: "05",
    image: "./images/zibadent/story/5.jpg",
    popupLink: ["./images/zibadent/story/5.jpg"],
  },

  {
    id: "06",
    image: "./images/zibadent/story/6.jpg",
    popupLink: ["./images/zibadent/story/6.jpg"],
  },
];

const numrows = 227;
const rows = [];
for (var i = 1; i < numrows; i++) {
  rows.push(`/images/diopars/SymposiumPhoto/${i}.jpg`);
}

const InstagramMarketing = () => {
  return (
    <>
      <SEO title="Zibadent Dental Clinic" />

      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-6z shape-left inheader-not-#2a115700 height-750">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                {/* <div className="inner text-start">
                  <h1 className="title color-white">Ziba Dent Dental Clinic</h1>
                </div> */}
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                {PopupData.map((item) => (
                  <div className="video-btn" key={item.id}>
                    <VideoTwo
                      imagename="./images/zibadent/smile.jpg"
                      galleryItem={item}
                    />
                  </div>
                ))}
              </div>
              <div className="shape-image">
                <img
                  src="./images/banner/white-shape.svg"
                  alt="2nd Diopars International Digital Implantology Symposium"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <AboutZibadent />
        {/* Start Elements Area  */}
        <div className="rwt-gallery-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--20">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Instagram Marketing"
                  title="Zibadent Posts"
                  description=""
                />
              </div>
            </div>
            <div className="row mt_dec--30 row--15">
              {PopupData2.map((item) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                  key={item.id}
                >
                  <GalleryOne galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-gallery-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--20">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Instagram Marketing"
                  title="Instgram Stories"
                  description=""
                />
              </div>
            </div>
            <div className="row mt_dec--30 row--15">
              {PopupData3.map((item) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                  key={item.id}
                >
                  <GalleryOne galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        <Copyright />
      </main>
    </>
  );
};

export default InstagramMarketing;
