import React from "react";
const AboutZibadent = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/zibadent/zibadentCover.png"
                alt="Zibadent Instagram Marketing"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  Zibadent Instagram <br />{" "}
                </h2>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Zibadent's Instagram is a good example for managing and
                  marketing Instagram pages.
                  <br />
                  On this page, I have put all the posts of a month as well as
                  the posting schedule.
                  <br />
                  In this marketing campaign, I have tried to attract the
                  attention of the audience in a new way by taking inspiration
                  from natural beauty and combining it with cosmetic dentistry.
                  <br />
                  Also, in this Instagram marketing campaign, 2 videos were made
                  to introduce the services of Zibadent dental clinic, which is
                  inspired by the main idea of this campaign.
                  <br />I always create a special method and a unique story to
                  create content for managing Instagram pages.
                  <br />I believe that marketing is a real art and to be a good
                  marketer we must have a deep understanding of the art,
                  especially the art of designing and making videos.
                </p>
                <div className="download-button mt--20">
                  <a
                    className="btn-read-more"
                    href="https://rismanchi.com/pdfs/zibadentCalendar.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Instagram Content Calendar </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutZibadent;
