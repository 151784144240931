import { createSlice } from "@reduxjs/toolkit";

const portfoliosSlice = createSlice({
  name: "portfolios",
  initialState: {
    categories: [],
    entities: [],
    selected: null,
  },
  reducers: {
    loadPortfolio(state, action) {
      state.entities = action.payload;
    },
    loadCategories(state, action) {
      state.categories = action.payload;
    },
    selectedPortfolio(state, action) {
      state.selected = action.payload;
    },
    addPortfolio(state, action) {
      const newPortfolio = action.payload;
      const existingPortfolio = state.entities.find(
        (entitie) => entitie.id === newPortfolio.id
      );
      if (!existingPortfolio) {
        state.entities.push({
          id: newPortfolio.id,
          portfolioImage: newPortfolio.portfolioImage,
          imgae: newPortfolio.portfolioImage,
          title: newPortfolio.title,
          subtitle: newPortfolio.subtitle,
          date: newPortfolio.date,
          category: newPortfolio.category,
        });
      } else {
      }
    },
  },
});

export const portfoliosActions = portfoliosSlice.actions;
export default portfoliosSlice;
