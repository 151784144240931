import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterThree from "../common/footer/FooterThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";

import ProgressbarThree from "../elements/progressbar/ProgressbarThree";
import TestimonialThree from "../elements/testimonial/TestimonialThree";

import { FiArrowDown } from "react-icons/fi";
import Typed from "react-typed";
import MakeWave from "../components/wave";
import AboutOne from "../elements/about/AboutOne";
import BrandWave from "../elements/brand/BrandWave";

import { isMobile } from "react-device-detect";
import VfxRGB from "../components/VfxRGB";
const PersonalPortfolio = () => {
  return (
    <>
      <SEO title="Full Stack Developer" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        {/* <MyTopo> */}
        {/* <MyBack></MyBack> */}
        {/* Start Slider Area  */}
        {/* Start Slider Area  */}
        <MakeWave></MakeWave>
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <div className="react-image mb--30" style={{ height: 127 }}>
                    <VfxRGB
                      width={127}
                      height={127}
                      url="./images/logo/SignWhite.png"
                      alt="Mehdi Rismanchi"
                    />
                  </div>
                  <span className="subtitle" style={{ color: "white" }}>
                    Hi There! I'm{" "}
                    {/* <span style={{ fontWeight: "bold" }}>
                        Mehdi Rismanchi
                      </span> */}
                  </span>
                  <h1 className="title theme-gradient display-two">
                    Mehdi Rismanchi <br />{" "}
                    {isMobile ? (
                      ""
                    ) : (
                      <Typed
                        style={{ fontSize: 52 }}
                        strings={[
                          "Full Stack Developer",
                          "Python, Django, React",
                        ]}
                        typeSpeed={120}
                        backSpeed={20}
                        backDelay={2000}
                        loop
                      />
                    )}
                  </h1>

                  <div className="button-group">
                    <a
                      className="btn-default btn-medium btn-border round btn-icon"
                      href="#aboutme"
                    >
                      Get In Touch <FiArrowDown />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* <Separator /> */}
        <div id="aboutme">
          <AboutOne />
        </div>

        {/* Start Brand Area  */}
        <div className="rwt-brand-area mb--150">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-5">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Work Experince"
                  title="Employers & Clinets"
                  description=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandWave />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Portfolio Area  */}
        <div className="rwt-portfolio-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Project"
                  title="Portfolio"
                  description=""
                />
              </div>
            </div>

            <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>
        {/* End Portfolio Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-about-area rn-section-gap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <SectionTitle
                  textAlign="text-start"
                  radiusRounded=""
                  subtitle="See my All Skill."
                  title="My Working Skills"
                  description="I have several specialties, but they are all related in some way<br> and help me bring my ideas to life."
                />
                <div className="download-button mt--20">
                  <a className="btn-read-more" href="/about-me">
                    <span>Read More About me</span>
                  </a>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="rbt-progress-style-1">
                  <ProgressbarThree />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="Certificates & Appreciations"
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Latests News"
                  title="Read from the Blog."
                  description="We provide company and finance service for <br /> startups and company business."
                />
              </div>
            </div>
            <div className="row row--15">
              {BlogListData.map((item) => (
                <div
                  key={item.id}
                  className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                >
                  <BlogList StyleVar="box-card-style-default" data={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* </MyTopo> */}
        <FooterThree />
      </main>
    </>
  );
};
export default PersonalPortfolio;
