import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import TRUNK from "./vanta/trunk";
import { isMobile } from "react-device-detect";
import p5 from "p5";
export default function AboutCircle(props) {
  const [circleEffect, setCircleEffect] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      if (!circleEffect) {
        setCircleEffect(
          TRUNK({
            el: myRef.current,
            p5: p5,
            THREE: THREE,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
          })
        );
      }
      return () => {
        if (circleEffect) circleEffect.destroy();
      };
    }
  }, [circleEffect]);

  return (
    <>
      <section className={props.className} ref={myRef}>
        {props.children}
      </section>
    </>
  );
}
