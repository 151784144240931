import React from "react";
import VideoTwo from "../elements/video/VideoTwo";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import GalleryOne from "../elements/gallery/GalleryOne";
import AboutCafeDio from "../elements/about/AboutCafeDio";
const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: ["./videos/cafedio/Part3.mp4"],
  },
];

const PopupData2 = [
  {
    id: "01",
    image: "./images/diopars/bou-01.jpg",
    popupLink: [
      "./images/diopars/bou-01.jpg",
      "./images/diopars/bou-02.jpg",
      "./images/diopars/bou-03.jpg",
    ],
  },
  {
    id: "02",
    image: "./images/diopars/poster-01.jpg",
    popupLink: ["./images/diopars/poster-01.jpg"],
  },

  {
    id: "03",
    image: "./images/diopars/card-01.jpg",
    popupLink: ["./images/diopars/card-01.jpg"],
  },
];

const PopupData3 = [
  {
    id: "01",
    image: "./images/diopars/bayat.jpg",
    popupLink: ["./videos/DrBayat.mp4"],
  },
  {
    id: "02",
    image: "./images/diopars/atri.jpg",
    popupLink: ["./videos/DrAtri.mp4"],
  },

  {
    id: "03",
    image: "./images/diopars/mashoof.jpg",
    popupLink: ["./videos/DrMashouf.mp4"],
  },
  {
    id: "04",
    image: "./images/diopars/morshedi.jpg",
    popupLink: ["./videos/DrMorshedi.mp4"],
  },
  {
    id: "05",
    image: "./images/diopars/mafi.jpg",
    popupLink: ["./videos/DrMafi.mp4"],
  },
  {
    id: "06",
    image: "./images/diopars/baghani.jpg",
    popupLink: ["./videos/DrBaghani.mp4"],
  },
];

const PopupData4 = [
  {
    id: "01",
    image: "./images/cafedio/amid.jpg",
    popupLink: ["./videos/cafedio/amid.mp4"],
  },
  {
    id: "02",
    image: "./images/cafedio/hamed.jpg",
    popupLink: ["./videos/cafedio/hamed.mp4"],
  },
];
const numrows = 227;
const rows = [];
for (var i = 1; i < numrows; i++) {
  rows.push(`/videos/cafedio/amid.mp4`);
}

const PopupData5 = [
  {
    id: "01",
    image: "./images/diopars/symAllhight.jpg",
    popupLink: rows,
  },
];

const CafeDio = () => {
  return (
    <>
      <SEO title="Cafe DIO" />

      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-7c shape-left inheader-not-#2a115700 height-750">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <p className="description color-white">DIOPARS</p>
                  <h1 className="title color-white">
                    DIO Cafe
                    <br /> Talk Show
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                {PopupData.map((item) => (
                  <div className="video-btn" key={item.id}>
                    <VideoTwo
                      imagename="./images/cafedio/VideoCoveCafeDio.jpg"
                      galleryItem={item}
                    />
                  </div>
                ))}
              </div>
              <div className="shape-image">
                <img
                  src="./images/banner/white-shape.svg"
                  alt="2nd Diopars International Digital Implantology Symposium"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <AboutCafeDio />

        {/* Start Elements Area  */}
        <div className="rwt-gallery-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--20">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="DIO Cafe"
                  title="DIO Cafe Videos"
                  description=""
                />
              </div>
            </div>
            <div className="row mt_dec--30 row--15">
              {PopupData4.map((item) => (
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30"
                  key={item.id}
                >
                  <GalleryOne galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        <Copyright />
      </main>
    </>
  );
};

export default CafeDio;
