import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import VfxRGB from "../../components/VfxRGB";
const Logo = ({ image, image2 }) => {
  return (
    <div className="logo">
      <Link to={process.env.PUBLIC_URL + "/"}>
        <VfxRGB
          url={process.env.PUBLIC_URL + image}
          className="logo-dark"
          alt="Logo Mehdi Rismanchi"
        />
      </Link>
    </div>
  );
};
Logo.propTypes = {
  image: PropTypes.string,
};

export default Logo;
